window.angular.module('MyHippoProducer').directive('autofocusafterclear', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $timeout(function () {
                $scope.$watch('filters.keyword', (newValue) => {
                    if (_.isEqual(newValue, '')) {
                        $element[0].focus();
                    }
                }, true);
            });
        },
    };
}]);